// Array of tech skills known to be displayed on the first TechStack module

export const techStackData = [
    'php',
    'laravel',
    'react',
    'javascript',
    'python',
    'java',
    'kubernetes',
    'aws',
    'bash',
    'docker',
    'sql'
];